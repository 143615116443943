import React, { Component } from 'react';
import construction from './imgs/construction.gif';
import './App.css';

class App extends Component {
  render() {
    return (
    <>
      <Construction />
      <br />
      <Blink>This site is under construction.</Blink>
    </>
    );
  }
}

const Construction = (props) => {
  return(
      <img className="construction" src={construction} alt="This site is under construction."/>
  );
}

const Blink = (props) => {
  return(
      <span className="blink">{props.children}</span>
  );
}

export default App;
